<div class="suplr-details">
  <span style="float: right;cursor: pointer;" class="material-icons" (click)="closeDialog()"> close </span>
  <h5>{{elementDetails.businessName}}</h5>
  <h6>Supplier Id: {{elementDetails.searchKey}}</h6>
  <div style="height: 91vh;overflow: auto;">
    <mat-accordion>
      <div class="detailsDiv">
        <div>
          <h3 class="cursor-pointer" (click)="showDetailForm()">Details</h3>
        </div>
        <form [formGroup]="detailForm" (ngSubmit)="detailsForm()" id="ngDetailForm" *ngIf="isDetailsVisible">
          <div class="form-details">
            <div class="FinanSection detailSection" fxLayout="Column">
              <label class="detailLabel">RM</label>
              <div class="fieldValue">
                <mat-form-field appearance="fill" class="selectearlypaystatus finan">
                  <mat-label>Choose RM</mat-label>
                  <mat-select (selectionChange)="onSelectRM($event.value)" formControlName="selectedRMName">
                    <mat-option *ngFor="let type of usersRMList" [value]="type.userId">
                      {{type.userName | titlecase}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="detailForm.controls.selectedRMName.errors?.required">This is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="FinanSection detailSection" fxLayout="Column">
              <label class="detailLabel">Stage</label>
              <div class="fieldValue">
                <mat-form-field appearance="fill" class="selectearlypaystatus finan">
                  <mat-label>Choose Stage</mat-label>
                  <mat-select (selectionChange)="onSelectStage($event.value)" formControlName="selectedStage">
                    <mat-option *ngFor="let type of stageArray" [value]="type.stage">
                      {{type.stage | titlecase}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="detailForm.controls.selectedStage.errors?.required">This is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="FinanSection detailSection" fxLayout="Column">
              <label class="detailLabel">Sub Stage</label>
              <div class="fieldValue">
                <mat-form-field appearance="fill" class="selectearlypaystatus finan">
                  <mat-label>Choose Sub Stage</mat-label>
                  <mat-select formControlName="selectedSubStage">
                    <mat-option *ngFor="let type of subStageArray" [value]="type">
                      {{type | titlecase}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="detailForm.controls.selectedSubStage.errors?.required">This is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <div class="actionbtn">
          <!-- <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button> -->
          <button form="ngDetailForm" type="submit" mat-raised-button class="savebtn">Save</button>
        </div>
      </div>
      <mat-expansion-panel [hideToggle]="true" [expanded]="true" *ngIf="!isLenderCheck">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Settings
          </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="supplierDetailsForm" (ngSubmit)="editDetails()" id="ngForm">
          <div class="form-details">
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>Rate%*</mat-label>
                <input type="number" matInput formControlName="rate" appNumbervalidation>
                <mat-error *ngIf="supplierDetailsForm.controls.rate.errors?.required">This is required.</mat-error>
              </mat-form-field>
              <span>%</span>
              <mat-form-field appearance="outline">
                <mat-label>Eligible%*</mat-label>
                <input type="number" matInput formControlName="eligiblePercentage" appNumbervalidation>
                <mat-error *ngIf="supplierDetailsForm.controls.eligiblePercentage.errors?.required">This is required.
                </mat-error>
              </mat-form-field>
              <span>%</span>
            </div>
            <mat-form-field class="itemlimit" appearance="outline">
              <mat-label>Limit</mat-label>
              <input type="number" matInput formControlName="limit" appNumbervalidation>
            </mat-form-field>
            <span>(INR)</span>
            <div fxLayout="row">
              <mat-form-field class="itemdscnt" appearance="outline">
                <mat-label>Change Max Discount Days</mat-label>
                <input type="number" matInput formControlName="maxDaysDiscount" (keypress)="numberOnly($event)">
              </mat-form-field>
              <mat-form-field class="itemdscnt" appearance="outline">
                <mat-label>Change Min Discount Days*</mat-label>
                <input type="number" matInput formControlName="minDaysDiscount" (keypress)="numberOnly($event)">
                <mat-error *ngIf="supplierDetailsForm.controls.minDaysDiscount.errors?.required">This is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="apprSection">
          <label>Approvals</label>
          <div class="form-check" *ngFor="let apprName of approvalListArray">
            {{isChecked}}
            <input class="form-check-input" name="{{apprName.approvalName}}" type="checkbox"
              [(ngModel)]="apprName.isChecked" id="{{apprName.approvalName}}" (change)="getSelectedApproval()">
            <label class="form-check-label" for="{{apprName.approvalName}}">
              {{apprName.approvalName}}
            </label>
          </div>
        </div>

        <div class="FinanSection" fxLayout="Column">
          <label>Financer</label>
          <div class="fieldValue">
            <mat-form-field appearance="fill" class="selectearlypaystatus finan">
              <mat-label>Choose Financer</mat-label>
              <mat-select (selectionChange)="OnselectFinancer($event.value,financerArray )"
                [(ngModel)]="financeSelectType">
                <mat-option *ngFor="let type of financerArray" [value]="type.name">
                  {{type.name | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="edit">
            <mat-form-field appearance="outline">
              <mat-label>Credlix Fee</mat-label>
              <input type="number" matInput [(ngModel)]="credlixFee" *ngIf="isABFL == true">
              <input type="number" matInput [(ngModel)]="credlixMoglixFee" *ngIf="isMOGLIX" readonly>
            </mat-form-field>
            <span style="margin-left: 7px;">%</span>
          </div>
          <div *ngIf="showProgramName">
            <label>Program Details</label>
            <div class="fieldValue">
              <mat-form-field appearance="fill" class="selectearlypaystatus finan">
                <mat-label>Choose program type</mat-label>
                <mat-select [(ngModel)]="selectedProgramType">
                  <mat-option *ngFor="let type of programTypeArr" [value]="type.value">
                    {{type.name | titlecase}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="edit" *ngIf="showProgramName">                       
            <mat-form-field appearance="outline">
              <mat-label>Program Names</mat-label>
              <input type="text" (blur)="inputToArray()" matInput [(ngModel)]="programName">
            </mat-form-field>
          </div>

        </div>
        <!-- <button type="button" (click)="addContact()">ADD NEW</button> -->

        <div class="actionbtn">
          <!-- <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button> -->
          <button form="ngForm" type="submit" mat-raised-button class="savebtn">Save</button>
        </div>

      </mat-expansion-panel>

      <div class="whatsapp-details" *ngIf="authService.isCredlixUser() && !isLenderCheck">
        <form [formGroup]="bankDetailsForm" (ngSubmit)="submitBankDetails()" id="ngBankForm">
          <h2>Bank Account</h2>
          <div fxLayout="row">
            <mat-form-field class="itembankDetails" appearance="outline">
              <mat-label>Recipient Name*</mat-label>
              <input type="text" matInput formControlName="recipientName"
                [attr.disabled]="saveBtnLabel == 'Edit' ?'':null">
              <mat-error *ngIf="bankDetailsForm.controls.recipientName.errors?.required">This is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-form-field class="itembankDetails" appearance="outline">
              <mat-label>Account Number*</mat-label>
              <input type="text" matInput formControlName="accountNumber"
                [attr.disabled]="saveBtnLabel == 'Edit' ?'':null">
              <mat-error *ngIf="bankDetailsForm.controls.accountNumber.errors?.required">This is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-form-field class="itembankDetails" appearance="outline">
              <mat-label>IFSC*</mat-label>
              <input type="text" maxlength="11" matInput formControlName="ifscCode"
                [attr.disabled]="saveBtnLabel == 'Edit' ?'':null">
              <mat-error *ngIf="bankDetailsForm.controls.ifscCode.errors?.required">This is required.
              </mat-error>
            </mat-form-field>
          </div>
        </form>
        <div class="actionbtn">
          <button *ngIf="saveBtnLabel == 'Save'" form="ngBankForm" type="submit" mat-raised-button
            class="savebtn">Save</button>
          <button *ngIf="saveBtnLabel == 'Edit'" (click)="saveBtnLabel = 'Save'" mat-raised-button
            class="editBtn">Edit</button>
          <span *ngIf="isBankEditable" class="bankStatus">
            <span *ngIf="bankDetailsForm.controls.status.value == 'APPROVAL_PENDING'">Pending Approval</span>
            <span *ngIf="bankDetailsForm.controls.status.value != 'APPROVAL_PENDING'"
              style="color: #2E8400;">Approved</span>
            <i style="margin-left: 10px;" class="ri-information-line"></i>
          </span>
        </div>
      </div>


      <div class="whatsapp-details" *ngIf="authService.isCredlixUser() && !isLenderCheck">
        <h2>Contact</h2>
        <table>
          <tr *ngFor="let details of contactList; let i=index">
            <td>{{details.name}}</td>
            <td *ngIf="details.type != null && details.type =='PHONE'">{{details.phoneNumber}}</td>
            <td *ngIf="details.type != null && details.type =='EMAIL'">{{details.email}}</td>
            <td *ngIf="details.whatsapp== false && (details.type != null && details.type =='PHONE')" (click)="setWhatsappNumber(contactList[i], true)">Set for WhatsApp</td>
            <td *ngIf="details.whatsapp== true && (details.type != null && details.type =='PHONE')" title="Unset Message for whatsapp" (click)="setWhatsappNumber(contactList[i], false )"><img src="../../../../../assets//images/whatsapp.jpg" class="whtspicon">
            </td>
            <td *ngIf="details.whatsapp== false && (details.type != null && details.type =='EMAIL')" (click)="setWhatsappNumber(contactList[i], true)">Set for Email</td>
            <td *ngIf="details.whatsapp== true && (details.type != null && details.type =='EMAIL')" title="Unset Email" (click)="setWhatsappNumber(contactList[i], false )">
              <i class="ri-mail-line"></i>
            </td>
          </tr> 

        </table>
        <div class="add-n-btn">
          <a (click)="addContact()">+ Add New</a>
        </div>
      </div>

      <mat-expansion-panel [hideToggle]="true"
        *ngIf="(authService.isCredlixUser() && settingService.isLeadsPage) || authService.isLenderLeads()"
        [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Onboarding
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="details_section">
          <div class="wrap">
            <div>
              <mat-slide-toggle (change)="onValChange()" [(ngModel)]="onBoardToggle"
                [disabled]="pagesService.isDisable">
                Onboarding On</mat-slide-toggle>
            </div>
            <p style="margin-left: 44px;color: #808080;font-size: 11px;margin-top: 0px;">
              Show onboarding flow to supplier. They will be able to add business details and upload documents for
              lender approval.
            </p>
            <div class="form-group">
              <span>GSTIN</span>
              <i *ngIf="isGSTINExist" class="ri-checkbox-circle-line"></i>
              <input type="text" placeholder="Enter GSTIN" class="intBox" [(ngModel)]="GSTIN" readonly>
            </div>
            <div class="company_details" *ngIf="isCompanyDetailsVisible">
              <div class="full">
                <div class="half">
                  <h4>Company Name</h4>
                  <label>{{getCompanyDetails?.companyname}}</label>
                </div>
                <div class="half">
                  <h4>PAN</h4>
                  <label>{{getCompanyDetails?.pan}}</label>
                </div>
              </div>
              <div class="full">
                <div class="half">
                  <h4>Type Of Entity</h4>
                  <label>{{getCompanyDetails?.typeofEntity}}</label>
                </div>
                <div class="half">
                  <h4>CIN/LLPIN</h4>
                  <label>{{getCompanyDetails?.cin}}</label>
                </div>
              </div>

              <div class="full">
                <div class="half">
                  <h4>Registered Address</h4>
                  <label>{{getCompanyDetails?.registeredAddress}}</label>
                </div>
              </div>

              <div class="full">
                <div class="half">
                  <h4>Email</h4>
                  <label>{{getCompanyDetails?.email}}</label>
                </div>
                <div class="half" *ngIf="getCompanyDetails?.phone != 'null'">
                  <h4>Phone</h4>
                  <label>{{getCompanyDetails?.phone}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div class="company_details" *ngIf="directorDetails && directorDetails.length != 0">
              <table>
                <thead>
                  <tr>
                    <th>Director Name</th>
                    <th>PAN number</th>
                    <th>DOA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let details of directorDetails">
                    <td><span>{{details.fullName}}</span></td>
                    <td><span>{{details.incometaxDetail}}</span></td>
                    <td> <span>{{details.dateOfAppointment}} </span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="wrap" *ngIf="isDirectorSubmitted">
            <div class="compnyDcmnts">
              <div class="wrap-card">
                <label> PAN Card</label>
                <div class="dragArea">
                  <span class="lbl">
                    <label *ngIf="!filePan?.name && !pagesService.isDisable" for="file-upload"
                      class="custom-file-upload">
                      Select file to upload <i class="ri-attachment-line"></i>
                    </label>
                    <label *ngIf="!filePan?.name && pagesService.isDisable" for="file-upload"
                      class="custom-file-upload-disable">
                      No File Uploaded <i class="ri-attachment-line"></i>
                    </label>
                    <input style="display: none;" type="file" [multiple]="false" (click)="clearStack($event)"
                      (change)="onFileChanged($event, 'PAN')" #fileInput id="file-upload"
                      [disabled]="pagesService.isDisable">
                    <span *ngIf="filePan?.name">
                      <div class="file" title="{{filePan?.name}}">{{filePan?.name}}</div>
                      <button mat-button (click)="downloadDocuments('PAN')" class="view">View</button>
                      <button mat-button *ngIf="!pagesService.isDisable" (click)="close('PAN', filePan)"
                        class="remove">Remove
                      </button>
                    </span>
                  </span>
                </div>
              </div>
              <div class="wrap-card">
                <label> GST Registration Certificate</label>
                <div class="dragArea">
                  <span class="lbl">
                    <label *ngIf="!fileGst?.name && !pagesService.isDisable" for="file-upload1"
                      class="custom-file-upload">
                      Select file to upload<i class="ri-attachment-line"></i>
                    </label>
                    <label *ngIf="!fileGst?.name && pagesService.isDisable" for="file-upload1"
                      class="custom-file-upload-disable">
                      No File Uploaded<i class="ri-attachment-line"></i>
                    </label>
                    <input style="display: none;" type="file" [multiple]="false" (click)="clearStack($event)"
                      (change)="onFileChanged($event, 'GSTIN')" #fileInput1 id="file-upload1"
                      [disabled]="pagesService.isDisable">
                    <span *ngIf="fileGst?.name">
                      <span class="file" title="{{fileGst?.name}}">{{fileGst?.name}}</span>
                      <button mat-button (click)="downloadDocuments('GSTIN')" class="view">View</button>
                      <button mat-button (click)="close('GSTIN', fileGst)" class="remove"
                        *ngIf="!pagesService.isDisable">Remove</button>
                    </span>
                  </span>
                </div>
              </div>

              <div class="wrap-card">
                <label>Address proof</label>
                <div class="dragArea">
                  <span class="lbl">
                    <label *ngIf="!fileAddress?.name && !pagesService.isDisable" for="file-upload2"
                      class="custom-file-upload">
                      Select file to upload<i class="ri-attachment-line"></i>
                    </label>
                    <label *ngIf="!fileAddress?.name && pagesService.isDisable" for="file-upload2"
                      class="custom-file-upload-disable">
                      No File Uploaded<i class="ri-attachment-line"></i>
                    </label>
                    <input style="display: none;" type="file" [multiple]="false" (click)="clearStack($event)"
                      (change)="onFileChanged($event, 'Address')" #fileInput2 id="file-upload2"
                      [disabled]="pagesService.isDisable">
                    <span *ngIf="fileAddress?.name">
                      <span class="file" title="{{fileAddress?.name}}">{{fileAddress?.name}}</span>
                      <button mat-button (click)="downloadDocuments('ADDRESS_PROOF')" class="view">View</button>
                      <button mat-button (click)="close('ADDRESS', fileAddress)" class="remove"
                        *ngIf="!pagesService.isDisable">Remove</button>
                    </span>
                  </span>
                </div>
              </div>
              <div class="wrap-card">
                <label>Bank Statement (Past 1-year)</label>
                <div class="dragArea">
                  <span class="lbl">
                    <label *ngIf="!fileBank?.name && !pagesService.isDisable" for="file-upload3"
                      class="custom-file-upload">
                      Select file to upload<i class="ri-attachment-line"></i>
                    </label>
                    <label *ngIf="!fileBank?.name && pagesService.isDisable" for="file-upload3"
                      class="custom-file-upload-disable">
                      No File Uploaded<i class="ri-attachment-line"></i>
                    </label>
                    <input style="display: none;" type="file" [multiple]="false" (click)="clearStack($event)"
                      (change)="onFileChanged($event, 'BANK STATEMENT')" #fileInput3 id="file-upload3"
                      [disabled]="pagesService.isDisable">
                    <span *ngIf="fileBank?.name">
                      <span class="file" title="{{fileBank?.name}}">{{fileBank?.name}}</span>
                      <button mat-button (click)="downloadDocuments('BANK_DETAILS')" class="view">View</button>
                      <button mat-button (click)="close('BANK',fileBank)" class="remove"
                        *ngIf="!pagesService.isDisable">Remove</button>
                    </span>
                  </span>
                </div>
              </div>



              <div *ngFor="let dirInfo of directorInfo;let i = index">
                <div class="wrap-card">
                  <label>Director PAN Card - {{dirInfo.docName}}</label>
                  <div class="dragArea">
                    <span class="lbl">
                      <label *ngIf="!dirInfo?.name && !pagesService.isDisable" for="{{'file-upload_' + i}}"
                        class="custom-file-upload">
                        Select file to upload<i class="ri-attachment-line"></i>
                      </label>
                      <label *ngIf="!dirInfo?.name && pagesService.isDisable" for="{{'file-upload_' + i}}"
                        class="custom-file-upload-disable">
                        Select file to upload<i class="ri-attachment-line"></i>
                      </label>
                      <input style="display: none;" type="file" [multiple]="false" (click)="clearStack($event)"
                        (change)="onFileChanged($event, 'DIRPAN', dirInfo)" #[{{i}}] id="{{'file-upload_' + i}}"
                        [disabled]="pagesService.isDisable">
                      <span *ngIf="dirInfo?.name">
                        <span class="file" title="{{dirInfo?.name}}">{{dirInfo?.name}}</span>
                        <button mat-button (click)="downloadDocuments('DIRPAN', dirInfo)" class="view">View</button>
                        <button mat-button (click)="close('DIRPAN', dirInfo)" class="remove"
                          *ngIf="!pagesService.isDisable">Remove</button>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div *ngFor="let value of values; let i = index">
                <div class="wrap-card">
                  <div class="form-control">
                    <input style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" title="{{value?.docTypeName}}" id="{{i}}" type="text" [(ngModel)]="value['docTypeName']"
                      [ngClass]="value['name']? 'docUploadFile': 'docFile'" placeholder="Enter Document Name*"
                      [readonly]="value['name']">
                    <span *ngIf="!value['name']" style="color: red;margin-left: 5px">*</span>
                  </div>
                  <div class="dragArea">
                    <span class="lbl">
                      <label *ngIf="!value?.name" for="{{'new_file-upload_' + i}}"
                        [ngClass]="value['docTypeName'] == ''? 'custom-file-upload-disable':'custom-file-upload'">
                        Select file to upload<i class="ri-attachment-line"></i>
                      </label>
                      <input [disabled]="value['docTypeName'] == ''" style="display: none;" type="file"
                        [multiple]="false" (click)="clearStack($event)"
                        (change)="onFileChanged($event, 'New Document', value , i)" #[{{i}}]
                        id="{{'new_file-upload_' + i}}">

                      <span *ngIf="value?.name">
                        <span class="file" title="{{value?.name}}">{{value?.name}}</span>
                        <button mat-button (click)="downloadDocuments('OTHER', value)" class="view">View</button>
                        <button mat-button (click)="close('NDocument',value)" class="remove"
                          *ngIf="!pagesService.isDisable">Remove</button>
                      </span>
                      <!-- <button type="button" mat-button (click)="removevalue(i)">Remove</button> -->
                    </span>
                  </div>
                </div>
              </div>
              <button *ngIf="!pagesService.isDisable" [disabled]="disableDocBtn" type="button" mat-button
                [ngClass]="disableDocBtn?'disableDocbtn':'adddocbtn'" (click)="addvalue()">+
                Add New Document</button>


            </div>
          </div>
        </div>

        <div style="margin-top: 4vh;padding-bottom: 10px;">


          <p *ngIf="isDirectorSubmitted && !verifyFlag && onBoardToggle && !pagesService.isDisable"
            style="font-size: 13px;">
            Submitting the data will intimate lender to verify the buyer.
          </p>
          <button *ngIf="!isDirectorSubmitted && !verifyFlag && onBoardToggle" type="button" mat-button
            class="verifybtn" (click)="verifyInfo()" [disabled]="pagesService.isDisable">Submit</button>
          <button *ngIf="isDirectorSubmitted && !verifyFlag && onBoardToggle && !pagesService.isDisable" type="button"
            mat-button class="submitbtn" (click)="verifyInfo()">Submit</button>
          <button *ngIf="verifyFlag" type="button" mat-button class="verifybtn"
            (click)="downloadZip()">Download ZIP</button>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>