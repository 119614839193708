import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { ThrowStmt } from '@angular/compiler';
import { AuthService } from 'src/app/auth/auth.service';
import { SettingService } from 'src/app/settings/setting.service';
import { saveAs } from 'file-saver';
import { AddWhatsappcontactComponent } from '../supplier-profile/add-whatsappcontact/add-whatsappcontact.component';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-editdetails-dialog',
  templateUrl: './editdetails-dialog.component.html',
  styleUrls: ['./editdetails-dialog.component.scss']
})
export class EditdetailsDialogComponent implements OnInit {
  supplierDetailsForm: FormGroup;
  bankDetailsForm: FormGroup;
  detailForm: FormGroup;
  supplierDetails: any;
  checkedSupplierName: string
  ischecked: boolean;
  apprObj = {}
  approval = [];
  credlixFee: any;
  financerobj = {};
  financerType: string;
  credlixMoglixFee: number = 0;
  programName: any = [];
  showProgramName:boolean = this.authService.isCredlixUser();
  isABFL: boolean = true;
  isMOGLIX: boolean = false;
  ischeckFinanceName: string;
  financerArray = [];
  selectedMetricsValue = '-1';
  elementDetails: any;
  metricsDetails: any;
  metricsValue: any;
  metricsRes: any;

  approvalListArray: any = [
    { id: '1', approvalName: 'Supply Approval', field: 'supplyApproval', isChecked: false },
    { id: '2', approvalName: 'Finance Approval', field: 'financeApproval', isChecked: false },
  ];
  metricLevelArray: any = [
    { id: '1', name: 'All time', field: '-1' },
    { id: '2', name: '30 Days', field: '30' },
    { id: '3', name: '90 Days', field: '90' }
  ]

  programTypeArr = [
    {name: "Sales Invoice Discounting", value:"SID"}, {name: "Purchase Invoice Discounting", value: "PID"}, {name: "Vendor Finance", value: "VF"}
  ];
  selectedProgramType;
  financerID: any;
  financeSelectType: string;
  GSTIN: any;
  getCompanyDetails: any;
  isCompanyDetailsVisible: boolean = false;
  getOnboardData: any;
  isReadOnly: boolean = false;
  directorDetails = [];
  filePan: any;
  fileGst: any;
  fileAddress: any;
  fileBank: any;
  fileResponse: boolean = false;
  onBoardToggle: any;
  verifyFlag: boolean = false;
  directorInfo: any = []
  isGSTINExist: boolean = false;
  isDirectorSubmitted: boolean = false;
  isSubmitted: boolean = false;
  isLenderCheck: boolean = false;
  saveBtnLabel: string = "Save";
  isBankEditable: boolean = false;
  // new documents addition variables for bank account
  values = [];
  disableDocBtn: boolean = false;
  isFirst: boolean = true;
  usersRMList = [];
  selectedRMName : string;
  stageArray: any = [{stage: "C0-Cold", subStage: ["Not interested","Inactive","GST","blocked by Business"]}, {stage : "c0", subStage : ["Lead added in credlix","No Rm assigned", "No WIP"]}, {stage : "c1", subStage : ["Contacted", "follow-up"]}, {stage : "c2", subStage : ["Contacted", "Pitched"]},{stage: "c3", subStage : ["Hot can be converted"]}, {stage: "c4", subStage: ["Onboarded-Discounted"]}];
  selectedStage: string;
  subStageArray = [];
  selectedSubStage: string;
  isDetailsVisible: boolean = true;

  constructor(public dialogRef: MatDialogRef<EditdetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public dialog: MatDialog,
    public pagesService: PagesService, public toasterService: ToastrService,
    public authService: AuthService, public settingService: SettingService,) {
    this.elementDetails = data;
    if (this.elementDetails['islenderLogin']) {
      this.isLenderCheck = true;
    }
    this.onBoardToggle = this.elementDetails['isOnboarding']
    this.supplierDetails = data.config;
  }

  ngOnInit() {
    this.dialogRef.updatePosition({ top: '0px', right: '0px' });
    if (this.settingService.isLeadsPage && this.onBoardToggle != null) {
      this.getBusinessDetails();
    }
    else if (!this.settingService.isLeadsPage) {
      this.getFinancerList();
      this.getRmList();
      this.supplierDetailsForm = this.fb.group({
        rate: ['', Validators.required],
        eligiblePercentage: ['', Validators.required],
        limit: [''],
        maxDaysDiscount: [''],
        minDaysDiscount: ['', Validators.required],
      });
      this.bankDetailsForm = this.fb.group({
        recipientName: ['', Validators.required],
        accountNumber: ['', Validators.required],
        ifscCode: ['', Validators.required],
        id: [''],
        status: ['']
      });
      this.detailForm = this.fb.group({
        selectedRMName: ['',Validators.required],
        selectedStage: ['',Validators.required],
        selectedSubStage: ['',Validators.required],
      });
      for (let i = 0; i < this.approvalListArray.length; i++) {
        this.approvalListArray[i].isChecked = this.isApprovalSelected(this.supplierDetails, this.approvalListArray[i].field)
      }
    }
    this.getWhatsappList();
    this.getSupplierBankDetails();
    this.setDetailsForm();
  }

  isApprovalSelected(supplierConfig, approvalName) {
    let checked = false;
    if (supplierConfig.approvals != null) {
      let temp = supplierConfig.approvals.filter(data => {
        return data.field == approvalName
      })
      if (temp != null && temp.length > 0) {
        checked = temp[0].isActive
      }
    }
    return checked
  }
  inputToArray(){
    let array= this.programName.toString().split(',');
    return (array.length>0)? array : [];
  }
  numberOnly(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setSupplierDetails() {
    this.supplierDetailsForm.patchValue({
      rate: this.supplierDetails.discountRatePerDay,
      eligiblePercentage: this.supplierDetails.maxInvoiceDiscountpercentage,
      minDaysDiscount: this.supplierDetails.minDaysInterval

    })
    if (this.supplierDetails.limit == -1) {
      this.supplierDetailsForm.patchValue({
        limit: null
      })
    }
    else {
      this.supplierDetailsForm.patchValue({
        limit: this.supplierDetails.limit
      })
    }
    if (this.supplierDetails.maxDaysInterval == -1) {
      this.supplierDetailsForm.patchValue({
        maxDaysDiscount: null
      })
    }
    else {
      this.supplierDetailsForm.patchValue({
        maxDaysDiscount: this.supplierDetails.maxDaysInterval
      })
    }
    if (this.supplierDetails.financier != null) {
      this.financeSelectType = this.supplierDetails.financier.name;
      this.ischeckFinanceName = this.supplierDetails.financier.name;
      for (let i = 0; i < this.financerArray.length; i++) {
        if (this.ischeckFinanceName == this.financerArray[i].name) {
          this.financerID = this.financerArray[i].id
        }
      }
      if (this.financeSelectType == 'Aditya Birla Finance Limited' || this.financeSelectType == 'Cholamandalam Investment and Finance Company') {
        this.isABFL = true;
        this.isMOGLIX = false;
        this.credlixFee = this.supplierDetails.commission
      }
      else if(this.financeSelectType == 'Moglix'){
        this.isABFL = false;
        this.isMOGLIX = true;
        this.credlixMoglixFee = 0
      } else {
        this.isABFL = true;
        this.isMOGLIX = false;
        this.credlixFee = this.supplierDetails.commission
      }
    }

    if(this.showProgramName && this.supplierDetails.programNames && this.supplierDetails.programNames.length>0){
      this.programName = this.supplierDetails.programNames.join(',');
    }

  }

  editDetails() {
    if (this.supplierDetailsForm.value.rate > 100 || this.supplierDetailsForm.value.eligiblePercentage > 100) {
      this.toasterService.error("percentage can not be more than 100%");
    }
    else if (this.supplierDetailsForm.value.maxDaysDiscount != null && this.supplierDetailsForm.value.maxDaysDiscount != 0 &&
      this.supplierDetailsForm.value.maxDaysDiscount < this.supplierDetailsForm.value.minDaysDiscount) {
      this.toasterService.error("Maximum days should not be less than minimum days");
    }
    else {
      if (this.supplierDetailsForm.valid) {
        this.getSelectedApproval();
        let obj = {
          "supplierId": this.elementDetails.id,
          "discountRatePerDay": this.supplierDetailsForm.value.rate,
          "maxDisountAllowed": this.supplierDetailsForm.value.eligiblePercentage,
          "minDaysInterval": this.supplierDetailsForm.value.minDaysDiscount,
          "approval": this.approval
        }
        if(this.showProgramName){
          obj['programType'] = this.selectedProgramType;
          obj['programNames'] = this.inputToArray();
        }
        if ((this.ischeckFinanceName == 'Aditya Birla Finance Limited' || this.financeSelectType == 'Cholamandalam Investment and Finance Company') && this.credlixFee != null) {
          obj["financierId"] = this.financerID,
            obj["commission"] = this.credlixFee
        } else if ((this.financeSelectType == 'Aditya Birla Finance Limited' || this.financeSelectType == 'Cholamandalam Investment and Finance Company' || this.financeSelectType == 'TATA CAPITAL') && this.credlixFee == null) {
          this.toasterService.error("Please fill Credlix fee");
          return false;
        } else {
          obj["financierId"] = this.financerID,
          obj["commission"] = this.credlixFee
        }
        if (this.ischeckFinanceName == 'Moglix') {
          obj["financierId"] = this.financerID,
            obj["commission"] = null
        }
        if (this.supplierDetailsForm.value.limit == null) {
          obj['limit'] = -1
        }
        else {
          obj['limit'] = this.supplierDetailsForm.value.limit
        }
        if (this.supplierDetailsForm.value.maxDaysDiscount == null || this.supplierDetailsForm.value.maxDaysDiscount == 0) {
          obj['maxDaysInterval'] = -1
        }
        else {
          obj['maxDaysInterval'] = this.supplierDetailsForm.value.maxDaysDiscount
        }

        this.pagesService.editSupplierDetails(obj).subscribe(res => {
          if (res['status'] == 200) {
            this.dialogRef.close(true);
            this.pagesService.offSpinner();
            this.toasterService.success("Supplier detail edit successfully");
            this.selectedProgramType = null;
          }
        })
      }
    }
  }
  closeDialog() {
    this.dialogRef.close(true);
  }

  getSelectedApproval() {
    this.approval = []
    for (let i = 0; i < this.approvalListArray.length; i++) {
      if (this.approvalListArray[i].isChecked == true) {
        this.approval.push({
          "field": this.approvalListArray[i]['field'],
          "name": this.approvalListArray[i]['approvalName'],
          "type": "Boolean",
          "validValues": ["true"],
          "isActive": true
        })
      }
      else if (this.approvalListArray[i].isChecked == false) {
        this.approval.push({
          "field": this.approvalListArray[i]['field'],
          "name": this.approvalListArray[i]['approvalName'],
          "type": "Boolean",
          "validValues": ["true"],
          "isActive": false
        })
      }
    }

  }

  OnselectFinancer(value, element) {
    for (let i = 0; i < element.length; i++) {
      if (element[i].name == value) {
        if (value == 'Moglix') {
          this.isMOGLIX = true;
          this.isABFL = false;
          this.ischeckFinanceName = element[i].name
          this.financerID = element[i].id;
        }
        else if (value == 'Aditya Birla Finance Limited' || value == 'Cholamandalam Investment and Finance Company') {
          this.isMOGLIX = false;
          this.isABFL = true;
          this.ischeckFinanceName = element[i].name
          this.financerID = element[i].id;
        } else {
          this.isMOGLIX = false;
          this.isABFL = true;
          this.ischeckFinanceName = element[i].name
          this.financerID = element[i].id;
        }
      }
    }
    this.financerType = value;
  }


  getFinancerList() {
    let credlixUserID;
    if (this.authService.isCredlixUser()) {
      credlixUserID = localStorage.getItem('credlixUserID');
    }
    this.pagesService.fetchFinancer(credlixUserID).subscribe(list => {
      this.financerArray = list['result'];
      this.setSupplierDetails();

    })
  }

  getBusinessDetails() {
    this.settingService.checkGSTINExist(this.elementDetails['id']).subscribe(res => {
      this.directorInfo = [];
      this.values = [];
      this.getOnboardData = res['result'];
      if (this.getOnboardData['status'] != "SAVED" && this.getOnboardData['status'] != "GSTIN_SAVED") {
        this.isDirectorSubmitted = true;
        if (this.getOnboardData['status'] == "SUBMITTED" || this.getOnboardData['status'] == "SUBMITTED_CREDLIX") {
          this.isSubmitted = true;
        }
      }
      else if (this.getOnboardData['status'] == "GSTIN_SAVED" && this.getOnboardData['directors'] == null) {
        this.GSTIN = this.getOnboardData.gstin;
        this.settingService.fetchDirectorDetails(this.GSTIN, this.elementDetails['id']).subscribe(res => {
          this.directorDetails = res['result'];
        })
      }
      if (this.getOnboardData != null || this.getOnboardData != '') {
        this.directorDetails = this.getOnboardData['directors'];
        let documentInfo = this.getOnboardData['documentInfo']
        this.isReadOnly = true;
        this.isCompanyDetailsVisible = true;
        this.GSTIN = this.getOnboardData.gstin;
        this.getCompanyDetails = this.getOnboardData.comapnyInfo;
        if (documentInfo) {
          for (let i = 0; i < documentInfo.length; i++) {
            if (documentInfo[i].docType == 'GSTIN') {
              this.fileGst = documentInfo[i]
            }
            else if (documentInfo[i].docType == 'BANK_DETAILS') {
              this.fileBank = documentInfo[i]
            }
            else if (documentInfo[i].docType == 'ADDRESS_PROOF') {
              this.fileAddress = documentInfo[i]
            }
            else if (documentInfo[i].docType == 'PAN' && !documentInfo[i].docName) {
              this.filePan = documentInfo[i]
            }
            else if (documentInfo[i].docType == 'OTHER') {
              this.values.push(documentInfo[i])
            }
            if (documentInfo[i].docName) {
              this.directorInfo.push(documentInfo[i])
            }
          }
        }
        if (this.GSTIN) {
          if (!this.getCompanyDetails) {
            this.settingService.fetchCompanyDetails(this.GSTIN).subscribe(response => {
              this.isCompanyDetailsVisible = true;
              this.getCompanyDetails = response['result'];
            })
          }
          this.isGSTINExist = true;
        }
        if (this.getOnboardData['status'] == "SUBMITTED_CREDLIX") {
          this.verifyFlag = true;
        }
        else if (this.authService.isLenderLeads()) {
          this.verifyFlag = true;
        }

      }
    })
  }

  onFileChanged(event, value, dirInfo?, docValue?) {
    this.fileUpload(event, value, dirInfo, docValue);
  }

  fileUpload(event, value, dirInfo?, docValue?) {
    const uploadData = new FormData();
    let obj = {
      gstin: this.GSTIN,
      businessAccountId: this.elementDetails['id'],
    }
    if (dirInfo) {
      obj['docName'] = dirInfo.docName;
    }
    if (value == 'PAN') {
      this.filePan = event.target.files[0];
      obj['type'] = "PAN"
      uploadData.append('file', this.filePan, this.filePan.name);
    }
    else if (value == 'Address') {
      this.fileAddress = event.target.files[0];
      obj['type'] = 'ADDRESS_PROOF';
      uploadData.append('file', this.fileAddress, this.fileAddress.name);
    }
    else if (value == 'GSTIN') {
      this.fileGst = event.target.files[0];
      obj['type'] = 'GSTIN';
      uploadData.append('file', this.fileGst, this.fileGst.name);
    }
    else if (value == 'BANK STATEMENT') {
      this.fileBank = event.target.files[0];
      obj['type'] = 'BANK_DETAILS';
      uploadData.append('file', this.fileBank, this.fileBank.name);
    }
    else if (value == 'New Document') {
      let id = document.getElementById(String(docValue));
      obj['docName'] = id['value'];
      dirInfo.uploadFile = event.target.files[0];
      dirInfo.name = event.target.files[0].name;
      obj['type'] = 'OTHER';
      uploadData.append('file', dirInfo.uploadFile, dirInfo.name);
    }
    else if (value == 'DIRPAN') {
      dirInfo.uploadFile = event.target.files[0];
      dirInfo.name = event.target.files[0].name;
      obj['type'] = 'PAN';
      uploadData.append('file', dirInfo.uploadFile, dirInfo.name);
    }
    this.settingService.uploadDocuments(obj, uploadData).subscribe(file => {
      if (file['status'] == 200) {
        this.disableDocBtn = false;
        this.verifyFlag = false;
        if (value == 'PAN') {
          this.filePan = file['result'];
        }

        else if (value == 'Address') {
          this.fileAddress = file['result'];
        }
        else if (value == 'GSTIN') {
          this.fileGst = file['result'];
        }
        else if (value == 'BANK STATEMENT') {
          this.fileBank = file['result'];
        }
        else if (value == 'New Document') {
          let count;
          let docId = document.getElementById(String(docValue));
          this.values.map((val, id) => {
            if (docId['value'] == val['docTypeName']) {
              if (docValue != id) {
                count = id;
              }
            }
          })
          if (count != undefined) {
            this.values[count] = file['result'];
            this.values.splice(docValue, 1);
          }
          else {
            this.values[docValue] = file['result'];
          }
        }
        else if (value == 'DIRPAN') {
          dirInfo = file['result'];
        }
        this.toasterService.success("File has been Succesfully Uploaded");
      }
    })
  }

  clearStack(event) {
    event.target.value = null;
  }

  onValChange() {
    let businessID = [];
    businessID.push(this.elementDetails['id'])
    let obj = {
      onboFlag: this.onBoardToggle,
      businessAccountIds: businessID
    }
    if (localStorage.getItem('credlixUserID')) {
      obj['anchorMasterAccountId'] = localStorage.getItem('credlixUserID')
    }
    this.settingService.onBoardingOn(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.toasterService.success(res['result'][this.elementDetails['id']]);
        this.getBusinessDetails();
      }
    })
  }

  close(value, file?) {
    this.settingService.deleteDocuments(file.id, this.elementDetails['id']).subscribe(res => {
      if (res['status'] == 200) {
        this.verifyFlag = false;
        if (value == 'PAN') {
          this.filePan = null;
        }
        if (value == 'GSTIN') {
          this.fileGst = null;
        }
        if (value == 'ADDRESS') {
          this.fileAddress = null;
        }
        if (value == 'BANK') {
          this.fileBank = null;
        }
        if (value == 'NDocument') {
          this.values = this.values.filter(val => {
            return file.docTypeName != val.docTypeName
          })
        }
        if (value == "DIRPAN") {
          file.name = null;
          this.directorInfo.map(val => {
            if (file.name == val.name) {
              val.name = null;
            }
          })
        }
      }
    })
  }

  result;
  downloadDocuments(value, dirInfo?) {
    let obj = {
      gstin: this.GSTIN,
      businessAccountId: this.elementDetails['id'],
    }
    if (dirInfo && value != 'OTHER') {
      obj['docName'] = dirInfo.name;
    }
    if (value == 'PAN') {
      obj['docType'] = 'PAN'
    }
    else if (value == 'GSTIN') {
      obj['docType'] = 'GSTIN'
    }
    else if (value == 'BANK_DETAILS') {
      obj['docType'] = 'BANK_DETAILS'
    }
    else if (value == 'OTHER') {
      obj['docType'] = 'OTHER';
      obj['docName'] = dirInfo.docTypeName;
    }
    else if (value == 'ADDRESS_PROOF') {
      obj['docType'] = 'ADDRESS_PROOF'
    }
    else if (value == "DIRPAN") {
      obj['docType'] = 'PAN'
    }
    this.settingService.uploadDocumentsDownload(obj).subscribe(res => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      this.result = this.result.replace(/"/g, '');
      saveAs(res.body, this.result);
      return this.result.replace(/"/g, '');
    });

  }

  verifyInfo() {
    if (this.disableDocBtn) {
      this.disableDocBtn = false;
      this.values.pop();
    }
    let obj = {
      gstin: this.GSTIN,
      businessAccountId: this.elementDetails['id']
    }
    if (!this.directorDetails || this.directorDetails.length == 0) {
      this.settingService.saveCompany(obj).subscribe(res => {
        if (res['status'] == 200) {
          this.settingService.fetchDirectorDetails(this.GSTIN, this.elementDetails['id']).subscribe(res => {
            this.directorDetails = res['result'];
          })
        }
      })
    }
    else if (!this.isDirectorSubmitted) {
      this.settingService.saveDirectorDetails(obj).subscribe(res => {
        if (res['status'] == 200) {
          this.isDirectorSubmitted = true;
        }
      })
    }
    else if (this.isSubmitted) {
      this.settingService.verifyInfo(this.elementDetails['id']).subscribe(res => {
        this.verifyFlag = true;
        this.toasterService.success("Submitted");
      })
    }
    else {
      this.settingService.submitOnBoardDetails(obj).subscribe(res => {
        this.settingService.verifyInfo(this.elementDetails['id']).subscribe(res => {
          this.verifyFlag = true;
          this.isSubmitted = true;
          this.toasterService.success("Submitted");
        })
      });
    }
  }


  addContact() {
    const dialogRef = this.dialog.open(AddWhatsappcontactComponent, {
      width: '450px',
      height: '370px',
      data: this.elementDetails
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getWhatsappList();
    })
  }
  contactList = []
  getWhatsappList() {
    this.settingService.fetchWhatsappUserList(this.elementDetails['id'], null, null).subscribe(respo => {
      this.contactList = respo['result'];
    })
  }

  setWhatsappNumber(element, isWhatsAppTrue) {
    console.log(element)
    let obj = {
      businessAccountId: this.elementDetails['id'],
      phoneNumber: element.phoneNumber,
      userId: element.id
    }
    if (element.subUserId != null) {
      obj['subUserId'] = element.subUserId
    }
    if(isWhatsAppTrue == true){
      obj['whatsapp']= true
    }
    else if(isWhatsAppTrue == false){
      obj['whatsapp']= false
    }

    if (isWhatsAppTrue == true) {
      obj['whatsapp'] = true
    }
    else if (isWhatsAppTrue == false) {
      obj['whatsapp'] = false
    }
    this.settingService.setWhatsappNumber(obj).subscribe(res => {
      if (res['result'] == true) {
        if (isWhatsAppTrue == false) {
          if(element.type == 'EMAIL'){
            this.toasterService.success("Email for Notification has been reset successfully");
          }
          else {
          this.toasterService.success("Phone number for Notification has been reset successfully");
          }
        }
        this.getWhatsappList();
      }
    });
  }


  downloadZip() {
    this.settingService.downloadZip(this.elementDetails['id']).subscribe(res => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      saveAs(res.body, this.result);
      return this.result.replace(/"/g, '');
    })
  }

  submitBankDetails() {
    let obj = {
      "accountNumber": this.bankDetailsForm.value.accountNumber,
      "id": this.bankDetailsForm.value.id,
      "businessAccountId": this.elementDetails['id'],
      "ifscCode": this.bankDetailsForm.value.ifscCode,
      "recipientName": this.bankDetailsForm.value.recipientName
    };
    if (this.bankDetailsForm.valid) {
      if (!this.isBankEditable) {
        this.pagesService.saveSupplierBankDetails(obj).subscribe(res => {
          if (res['status'] == 200) {
            let data = res['result'];
            this.bankDetailsForm.patchValue({
              recipientName: data['recipientName'],
              accountNumber: data['accountNumber'],
              ifscCode: data['ifscCode'],
              id: data['id'],
              status: data['status']
            })
            this.isBankEditable = true;
            this.saveBtnLabel = "Edit";
            this.toasterService.success("Supplier bank details saved successfully");
          }
        })
      }
      else {
        this.pagesService.editSupplierBankDetails(obj).subscribe(res => {
          if (res['status'] == 200) {
            let data = res['result'];
            this.bankDetailsForm.patchValue({
              recipientName: data['recipientName'],
              accountNumber: data['accountNumber'],
              ifscCode: data['ifscCode'],
              id: data['id'],
              status: data['status']
            })
            this.isBankEditable = true;
            this.saveBtnLabel = "Edit";
            this.toasterService.success("Supplier bank details Updated successfully");
          }
        })
      }
    }
  }

  getSupplierBankDetails() {
    this.pagesService.getSupplierBankDetails(this.elementDetails['id']).subscribe(res => {
      if (res['result'].length != 0) {
        this.isBankEditable = true;
        let data;
        res['result'].map(val => {
          if (val['isPrimary']) {
            data = val;
          }
        })
        this.saveBtnLabel = "Edit";
        this.bankDetailsForm.patchValue({
          recipientName: data['recipientName'],
          accountNumber: data['accountNumber'],
          ifscCode: data['ifscCode'],
          id: data['id'],
          status: data['status']
        })
      }
    })
  }

  addvalue() {
    let myDate = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
    this.disableDocBtn = true;
    this.values.push({ value: "", docTypeName: 'doc_' + myDate });
  }
  removevalue(i) {
    this.values.splice(i, 1);
  }
  getRmList(){
    this.pagesService.getRMList().subscribe(res =>{
      this.usersRMList = res['result']
    })
  }
  detailsForm(){
    let obj = {
      businessAccountId : this.elementDetails.id,
      rmName : this.detailForm.controls.selectedRMName.value,
      stage : this.detailForm.controls.selectedStage.value,
      subStage : this.detailForm.controls.selectedSubStage.value
    }
    this.pagesService.setBasicDetails(obj).subscribe(res =>{
      this.toasterService.success("Basic Details Saved");
    })
  }
  onSelectRM(value){
    this.selectedRMName = value;
  }
  onSelectStage(value){
    this.detailForm.controls['selectedSubStage'].reset();
    this.stageArray.forEach(val => {
      if(val.stage == value){
        this.subStageArray = val.subStage
      }
    })
  }

  showDetailForm(){
    this.isDetailsVisible = !this.isDetailsVisible
  }
  setDetailsForm(){
    if(this.data.rmName && this.data.rmName.id){
      this.detailForm.patchValue({
        selectedRMName: this.data['rmName']['id']
      })
    }
    if(this.data.stage){
      this.stageArray.forEach(val => {      // to set subStage array for proper data binding in Sub Stage field
        if(val.stage == this.data.stage){
          this.subStageArray = val.subStage
        }
      })
      this.detailForm.patchValue({
        selectedStage: this.data['stage']
      })
    }
    if(this.data.subStage){
      this.detailForm.patchValue({
        selectedSubStage: this.data['subStage']
      })
    }
  }

}
