<div class="pre-qualified">
  <div class="container-qualified">
    <div class="submit-btn">
      <button class="nextButton cursor-pointer" *ngIf="selectedIndex !== 4" (click)="nextTab()"
        [disabled]="isDisable">Next</button>
      <button class="cursor-pointer" (click)="saveButton()" [disabled]="isDisable">Save</button>
      <button class="moveBtn cursor-pointer" *ngIf="selectedIndex == 4" (click)="submitDetails()"
        [disabled]="isDisable">Move TO LOS</button>
    </div>
    <div class="tabing-box">
      <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
        <mat-tab label={{tabLabelName[0]}}>
          <div class="input-main-div">
            <div class="basic-detail">
              <h3>Basic Details</h3>
              <div class="input-wrap-div">
                <div class="box-width">
                  <div class="formfield">
                    <label>{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Exporter"}} Legal Name</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Exporter Lead's Name." [(ngModel)]="leadDetails.exporterName"
                        [disabled]="isDisable">
                      <button class="handle-panjiva-button"
                        [ngClass]="{'blue': panjivaButtonState === 'getDetails', 'green' : panjivaButtonState === 'viewDetails'}"
                        [@buttonAnimation]="panjivaButtonState" [class.disabled-button]="!leadDetails.exporterName"
                        (click)="handlePanjivaState()" [disabled]="!leadDetails.exporterName"> <span>{{
                          panjivaButtonState === 'getDetails' ? 'Get Details' : 'View Data' }}</span></button>
                    </mat-form-field>

                  </div>
                </div>
                <div class="box-width">
                  <div class="formfield">
                    <label>City</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter City" [(ngModel)]="leadDetails.exporterCity"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width select-box">
                  <div class="formfield">
                    <label>State</label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Enter State" [(ngModel)]="leadDetails.exporterState"
                        [disabled]="isDisable">
                        <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="input-wrap-div mt-20">
                <div class="box-width">
                  <div class="formfield">
                    <label>PAN No.</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Pan No." [(ngModel)]="leadDetails.exporterPanNo"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width-big">
                  <div class="formfield">
                    <label>Full Address</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Full Address" [(ngModel)]="leadDetails.exporterAddress"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-wrap-div" style="padding: 20px 0 0 0;">
              <div class="box-width">
                <div class="formfield">
                  <label>Business Vintage</label>
                  <mat-form-field class="mog3_matinput" appearance="outline">
                    <input class="vintageInp" matInput placeholder="Enter Business Vintage" [(ngModel)]="leadDetails.businessVintage" [disabled]="true">
                  </mat-form-field>
                </div>
              </div>
              <div class="box-width">
                <div *ngIf="leadDetails.businessType == 'Domestic'" class="formfield">
                  <label>{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Type </label>
                  <mat-form-field class="mog3_matinput" appearance="outline">
                    <input matInput placeholder="Enter Supplier/Client Type" [(ngModel)]="leadDetails.supplierType" [disabled]="isDisable">
                  </mat-form-field>
                </div>
                <div *ngIf="leadDetails.businessType != 'Domestic'" class="formfield">
                  <label>Company Type</label>
                  <mat-form-field class="mog3_matinput" appearance="outline">
                    <input matInput placeholder="Enter Company Type" [(ngModel)]="leadDetails.companyType" [disabled]="isDisable">
                  </mat-form-field>
                </div>
              </div>
              <div class="box-width">
                <div *ngIf="leadDetails.businessType == 'Domestic'" class="formfield">
                  <label>Nature of Business</label>
                  <mat-form-field class="mog3_matinput" appearance="outline">
                    <input matInput placeholder="Enter Nature of Business" [(ngModel)]="leadDetails.natureOfBusiness" [disabled]="isDisable">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input-wrap-div" style="padding: 20px 0 0 0;">
              <div *ngIf="leadDetails.businessType == 'Domestic'" class="box-width">
                <div class="formfield">
                  <label>Product Type</label>
                  <mat-form-field class="mog3_matinput" appearance="outline">
                    <input matInput placeholder="Enter Product Type" [(ngModel)]="leadDetails.productType" [disabled]="isDisable">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="contact-detail mt-40">
              <h3>Contact Details</h3>
              <div class="basic-detail">
                <div #contactContent class="content-box">
                  <form [formGroup]="contactForm" (ngSubmit)="doSubmit()">
                    <div class="form-box" formArrayName="details"
                      *ngFor="let field of contactForm.get('details')?.controls; let ind = index;">
                      <div [formGroupName]="ind">
                        <div class="separator">
                          <h2>SPOC Details {{ind + 1}}</h2>
                          <div class="line"></div>
                          <button class="remove-btn" (click)="deleteContactRow(field,ind)" [disabled]="isDisable">
                            <span><i class="ri-delete-bin-line"></i></span>
                            Remove
                          </button>
                        </div>
                        <div class="input-wrap-div">
                          <div class="box-width">
                            <div class="formfield">
                              <label>SPOC Name <span class="text-danger">*</span></label>
                              <mat-form-field class="mog3_matinput" appearance="outline">
                                <input matInput placeholder="SPOC Name" formControlName="name" [readonly]="isDisable">
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="box-width select-box">
                            <div class="formfield">
                              <label>Designation <span class="text-danger">*</span></label>
                              <mat-form-field class="mog3_matinput" appearance="outline">
                                <input matInput placeholder="Designation" formControlName="designation"
                                  [readonly]="isDisable">
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="box-width">
                            <div class="formfield">
                              <label>Phone No. <span class="text-danger">*</span></label>
                              <div class="inner-phone-div">
                                <mat-form-field class="mog3_matinput-number" appearance="outline">
                                  <input matInput placeholder="Number" formControlName="phone" [readonly]="isDisable">
                                </mat-form-field>
                              </div>
                            </div>
                            <span class="check-span"><mat-checkbox (click)="makePrimary($event, ind)"
                                [disabled]="isDisable" formControlName="primary">Mark as Primary
                                Contact</mat-checkbox></span>
                          </div>
                        </div>
                        <div class="input-wrap-div mt-20">
                          <div class="box-width">
                            <div class="formfield">
                              <label>Email <span class="text-danger">*</span></label>
                              <mat-form-field class="mog3_matinput" appearance="outline">
                                <input matInput placeholder="Enter Email" formControlName="email"
                                  [readonly]="isDisable">
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="box-width">
                            <div class="formfield">
                              <label>Alternate Phone No.</label>
                              <div class="inner-phone-div">
                                <!-- <mat-form-field appearance="outline" class="mog3-matselect-phone">
                                  <mat-select placeholder="Code">
                                    <mat-option value="+91">+91</mat-option>
                                    <mat-option value="93">93</mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                                <mat-form-field class="mog3_matinput-number" appearance="outline">
                                  <input matInput placeholder="Number" formControlName="alternatePhone"
                                    [readonly]="isDisable">
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <button class="add-button" (click)="addContact()" [disabled]="isDisable || !isContactFormValid()"><span><i
                    class="ri-add-line"></i></span> Add Another SPOC</button>
            </div>
            <div class="other-detail mt-60">
              <h3>Other Details</h3>
              <div class="input-wrap-div">
                <div class="box-width">
                  <div class="formfield">
                    <label>Turnover - Annual Exports ( INR )</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Amount" [(ngModel)]="leadDetails.annualTurnoverValue"
                        [readonly]="isDisable">
                      <span matSuffix class="blue">Cr.</span>
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width select-box">
                  <div class="formfield">
                    <label>No of Shipments (Monthly)</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Amount" [(ngModel)]="leadDetails.noOfShipment"
                        [readonly]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width">
                  <div class="formfield">
                    <label>Shipment Amount ({{leadDetails.annualTurnoverCurrency}} + Value).</label>
                    <div class="inner-price-div">
                      <mat-form-field appearance="outline" class="mog3-matselect-phone">
                        <mat-select placeholder="₹" [(ngModel)]="leadDetails.annualTurnoverCurrency"
                          [disabled]="isDisable">
                          <mat-option value="$">$</mat-option>
                          <mat-option value="INR">INR</mat-option>
                          <mat-option value="Euro">Euro</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="mog3_matinput-number" appearance="outline">
                        <input matInput placeholder="Amount" [(ngModel)]="leadDetails.monthlyShipmentAmount"
                          [readonly]="isDisable">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-wrap-div">
                <div class="box-width">
                  <div class="formfield">
                    <label>Bank Charges</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Bank Charges" [(ngModel)]="leadDetails.bankLoan"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width">
                  <div class="formfield">
                    <label> Banker</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Banker" [(ngModel)]="leadDetails.banker"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width">
                  <div class="formfield">
                    <label>Credit Rating</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Credit Rating" [(ngModel)]="leadDetails.creditRating"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="leadDetails && leadDetails.businessType != 'Domestic'">
          <ng-template mat-tab-label>
            <div class="label-badge-container">
              <span class="tab-label">{{ getLabelNameList()[1] }}</span>
              <span matBadge="{{buyerCount}}" matBadgeOverlap="false" class="custom-badge"></span>
            </div>
          </ng-template>
          <div class="input-main-div mt-20">
            <div class="basic-detail">
              <div #buyerContent class="content-box">
                <form [formGroup]="keyValueForm" (ngSubmit)="doSubmit()">
                  <div class="form-box" formArrayName="items"
                    *ngFor="let field of keyValueForm.get('items')?.controls; let ind = index;">
                    <div [formGroupName]="ind">
                      <div class="separator">
                        <h2>Buyer Details {{ind + 1}}</h2>
                        <div class="line"></div>
                        <div>
                          <button class="remove-btn" (click)="deleteBuyerRow(field,ind)"
                            [ngClass]="{'disabled-button': isDisable}" [disabled]="isDisable">
                            <span><i class="ri-delete-bin-line"></i></span>
                            Remove
                          </button>
                        </div>
                      </div>
                      <div class="input-wrap-div">
                        <div class="box-width">
                          <div class="formfield">
                            <label>Buyer Legal Name <span class="mandatory">*</span></label>
                            <mat-form-field class="mog3_matinput" appearance="outline">
                              <input matInput placeholder="Enter Legal Name" formControlName="buyerName"
                                [readonly]="isDisable" [matAutocomplete]="auto"
                                (input)="searchBuyer($event.target.value)">
                              <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let buyer of buyerMatchings" [value]="buyer.name">
                                  <strong>{{ buyer.name }}</strong>&nbsp;|&nbsp;
                                  <span *ngIf="buyer.country"><i class="ri-global-fill"></i> {{ buyer.country
                                    }}</span>&nbsp;
                                  <span *ngIf="buyer.annual_revenue"><i class="ri-money-dollar-circle-fill"></i> {{
                                    buyer.annual_revenue | dollarStringFormat }}</span>&nbsp;
                                  <span *ngIf="buyer.industry"><i class="ri-building-4-fill"></i> {{ buyer.industry
                                    }}</span>
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <div class="has-error"
                              *ngIf="field.get('buyerName').invalid && field.get('buyerName').touched">
                              Buyer Legal name can't be empty
                            </div>
                          </div>
                        </div>
                        <div class="box-width-small select-box">
                          <div class="formfield">
                            <label>Buyer Region</label>
                            <mat-form-field appearance="outline" class="mog3-matselect">
                              <mat-select placeholder="Select" formControlName="buyerRegion" [disabled]="isDisable">
                                <mat-option value="US">US</mat-option>
                                <mat-option value="Europe">Europe</mat-option>
                                <mat-option value="Singapore">Singapore</mat-option>
                                <mat-option *ngIf="this.leadDetails.businessType == 'Domestic'" value="India">India</mat-option>
                                <mat-option value="Other">Other</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="box-width-small select-box">
                          <div class="formfield">
                            <label>Buyer Category</label>
                            <mat-form-field appearance="outline" class="mog3-matselect">
                              <mat-select placeholder="Select" formControlName="buyerCategory" [disabled]="isDisable">
                                <mat-option value="0-25">0-25 Million</mat-option>
                                <mat-option value="25-100">25-100 Million</mat-option>
                                <mat-option value="greater than 100 million"> Greater than 100 Million</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="input-wrap-div my-20">
                        <div class="box-width-small select-box">
                          <div class="formfield">
                            <label>Approved Buyer By</label>
                            <mat-form-field appearance="outline" class="mog3-matselect">
                              <mat-select placeholder="Select" formControlName="approvedBy" [disabled]="isDisable">
                                <mat-option value="Coface">Coface</mat-option>
                                <mat-option value="Olea">OLEA</mat-option>
                                <mat-option value="CIT">CIT</mat-option>
                                <mat-option value="DFB">DFB</mat-option>
                                <mat-option value="ABS">ABS</mat-option>
                                <mat-option value="TANNER">Tanner</mat-option>
                                <mat-option value="MONEX">Monex</mat-option>
                                <mat-option value="ROSENTHAL">Rosenthal</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <button class="add-button" (click)="addBuyer()"
                [ngClass]="{'disabled-button': !isBuyerFormValid() || isDisable }"
                [disabled]="!isBuyerFormValid() || isDisable"><span><i class="ri-add-line"></i></span> Add Another
                Buyer</button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label={{tabLabelName[2]}}>
          <div class="input-main-div mt-20">
            <div class="basic-detail">
              <div class="input-wrap-div">
                <div class="box-width-3 select-box">
                  <div class="formfield">
                    <label>Lead Source</label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Select Lead Source" [(ngModel)]="leadDetails.leadSource"
                        [disabled]="leadDetails && leadDetails.leadSource">
                        <mat-option *ngFor="let source of leadSourceList" [value]="source">{{source}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width-3">
                  <div class="formfield">
                    <label>Lead History</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter" [(ngModel)]="leadDetails.leadHistory" [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width-3 select-box">
                  <div class="formfield">
                    <label>{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Contact Established</label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Select" [(ngModel)]="leadDetails.supplierContacted"
                        [disabled]="isDisable">
                        <mat-option *ngFor="let con of supplierContacted" [value]="con.value">{{con.key}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <span class="chat-icon cursor-pointer" mat-button [matMenuTriggerFor]="chat">
                      <i class="ri-chat-4-line"></i>
                    </span>
                    <div class="chat-menu">
                      <mat-menu #chat="matMenu">
                        <button mat-menu-item (click)="redirectUser(true)" [disabled]="isDisable">
                          <span class="whtsapp"><i class="ri-whatsapp-line"></i></span>
                          WhatsApp
                        </button>
                        <button mat-menu-item (click)="redirectUser(false)" [disabled]="isDisable">
                          <span class="email"><i class="ri-mail-line"></i></span>
                          Email
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-wrap-div mt-40">
                <div class="box-width select-box">
                  <div class="formfield">
                    <label>{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Interested</label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Select" [(ngModel)]="leadDetails.supplierInterested"
                        [disabled]="isDisable">
                        <mat-option *ngFor="let con of supplierContacted" [value]="con.value">{{con.key}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width">
                  <div class="formfield">
                    <label>Last Contacted Date</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput [matDatepicker]="picker" placeholder="Select Date"
                        [(ngModel)]="leadDetails.lastContactedDate" [readonly]="disableDate">
                      <mat-datepicker-toggle [disabled]="isDisable" matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width">
                  <div class="formfield">
                    <label>Next Contact Date</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput [matDatepicker]="picker1" placeholder="Select Date"
                        [(ngModel)]="leadDetails.nextContactDate" [readonly]="disableDate">
                      <mat-datepicker-toggle [disabled]="isDisable" matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="lead-summary-text">
                <h4>Lead Summary</h4>
                <div class="box-1 mb-5">
                  <p class="icon-check"><i class="ri-check-double-line"></i> Available</p>
                  <p>
                    <span *ngIf="leadDetails?.buyerQualified">Buyer Qualified </span>
                    <span *ngIf="leadDetails?.annualTurnoverValue">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Turn Over </span>
                    <span *ngIf="leadDetails?.contactDetailsAdded">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Contact Details </span>
                    <span *ngIf="leadDetails?.supplierContacted">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Contacted </span>
                    <span *ngIf="leadDetails?.supplierInterested">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Interested </span>
                  </p>
                </div>
                <div class="box-1">
                  <p class="icon-cancel"><i class="ri-close-line"></i> Not Available</p>
                  <p>
                    <span *ngIf="!leadDetails?.buyerQualified">Buyer Qualified </span>
                    <span *ngIf="!leadDetails?.annualTurnoverValue">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Turn Over </span>
                    <span *ngIf="!leadDetails?.contactDetailsAdded">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Contact Details </span>
                    <span *ngIf="!leadDetails?.supplierContacted">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Contacted </span>
                    <span *ngIf="!leadDetails?.supplierInterested">{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Interested </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab> 
        <mat-tab *ngIf="leadDetails && leadDetails.businessType != 'Domestic'" label={{tabLabelName[3]}}>
          <div class="table-container mt-20">
            <div class="panjiva-table">
              <div class="header-container">
                <div class="header-info">
                  <div class="header-left">
                    <h4>{{leadDetails && leadDetails.businessType == 'Domestic' ? "Client" :"Supplier"}} Details</h4>
                  </div>
                  <div *ngIf="isSearched" class="status-indicator">
                    <span [ngClass]="{'status-dot': true, 'green': isPresent, 'red': !isPresent}"></span>
                    <span *ngIf="isPresent"> Matchings found for <b>'{{ leadDetails.exporterName }}'</b></span>
                    <span *ngIf="!isPresent">No matchings found for <b>'{{ leadDetails.exporterName }}'</b></span>
                  </div>
                </div>
                <div class="button-container">
                  <button *ngIf="!isPresent" class="request-button blue-button" (click)="requestPanjivaDetails()"
                    [disabled]="isDisable">Request Data from Panjiva</button>
                  <button class="request-button green-button" (click)="fetchPanjivaDetails()"
                    [disabled]="isDisable">Refresh</button>
                </div>
              </div>
              <div>
                <table mat-table [dataSource]="suppliers">

                  <!-------------------Suppler Name Column--------------------------->

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="cell-container">
                        <div class="icon-box"><a href="{{element?.profile_url}}"><i class="ri-building-line"></i></a>
                        </div>
                        <div class="cell-content">
                          <div class="name">{{element?.name || '--'}}</div>
                          <div class="details">
                            <div class="detail-item"><i class="ri-global-fill"></i><span>{{element?.country ||
                                '--'}}</span></div>
                            <div class="detail-item"><i class="ri-building-3-fill"></i><span>{{element?.industry ||
                                '--'}}</span></div>
                          </div>
                          <div class="detail-item"><i class="ri-team-fill"></i><span>{{element?.employee_count || '--'}}
                              employees</span></div>
                          <div class="detail-item"><i class="ri-calendar-fill"></i><span>{{element?.incorporation_date
                              || '--'}} Estd.</span></div>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <!-------------------Supplier Contact Column--------------------------->

                  <ng-container matColumnDef="contact">
                    <th mat-header-cell *matHeaderCellDef> Contact Info </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="contact-container"
                        *ngIf="element?.panjiva_contact_name || element?.panjiva_phone || element?.other_phone || element?.email || element?.address || element?.website; else noInfo">
                        <div *ngIf="element?.panjiva_contact_name" class="contact-item">
                          <i class="ri-user-fill"></i>
                          <span>{{element?.panjiva_contact_name}}</span>
                        </div>
                        <div *ngIf="element?.panjiva_phone || element?.email" class="phone-email">
                          <div *ngIf="element?.panjiva_phone" class="contact-item">
                            <i class="ri-phone-fill"></i>
                            <a href="tel:{{element?.panjiva_phone}}">{{element?.panjiva_phone}}</a>
                          </div>
                          <div *ngIf="element?.email" class="contact-item">
                            <i class="ri-mail-fill"></i>
                            <a href="mailto:{{element?.email}}">{{element?.email}}</a>
                          </div>
                        </div>
                        <div *ngIf="element?.address" class="contact-item">
                          <i class="ri-map-pin-fill"></i>
                          <span>{{element?.address}}</span>
                        </div>
                        <div *ngIf="element?.website" class="contact-item">
                          <i class="ri-global-fill"></i>
                          <a href="{{element?.website.startsWith('https') ? element?.website : 'https://' + element?.website}}"
                            target="_blank">
                            {{element?.website}}
                          </a>
                        </div>
                        <!-- Add similar blocks for other fields -->
                      </div>
                      <ng-template #noInfo><span>No info</span></ng-template>
                    </td>
                  </ng-container>

                  <!-------------------Supplier Finance Column--------------------------->

                  <ng-container matColumnDef="finance">
                    <th mat-header-cell *matHeaderCellDef> Finance </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="financial-metrics-container"
                        *ngIf="element?.annual_revenue !== null || element?.market_capital_value !== null; else noInfo">
                        <div class="financial-metric-item" *ngIf="element?.annual_revenue !== null">
                          <i class="ri-money-dollar-circle-fill"></i>
                          <span>Annual Turnover: ${{element?.annual_revenue | dollarStringFormat}}</span>
                        </div>
                        <div class="financial-metric-item" *ngIf="element?.market_capital_value !== null">
                          <i class="ri-bar-chart-fill"></i>
                          <span>Market Capitalization: ${{element?.market_capital_value | dollarStringFormat}}</span>
                        </div>
                      </div>
                      <ng-template #noInfo><span>No info</span></ng-template>
                    </td>
                  </ng-container>


                  <!-------------------Supplier Product Column--------------------------->

                  <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef> Product </th>
                    <td mat-cell *matCellDef="let element"> {{getProductDescription(element)}} </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
              </div>
            </div>
            <div class="panjiva-table mt-40">
              <div class="header-container">
                <div class="header-info"> <!-- New Wrapper -->
                  <div class="header-left">
                    <h4>Buyers Details</h4>
                  </div>
                  <div *ngIf="isSearched" class="status-indicator">
                    <span [ngClass]="{'status-dot': true, 'green': isPresent, 'red': !isPresent}"></span>
                    <span *ngIf="isPresent"><b>{{buyers.length}} buyers</b> found for <b>'{{ suppliers[0].name
                        }}'</b></span>
                    <span *ngIf="!isPresent">No Buyers for <b>'{{ leadDetails.exporterName }}'</b></span>
                  </div>
                </div>
              </div>
              <div class="table-buyer custom-scrollbar">
                <div class="custom-scrollbar">
                  <table mat-table [dataSource]="buyers">

                    <!-------------------Buyer Name Column--------------------------->

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="cell-container">
                          <div class="icon-box"><a href="{{element?.profile_url}}"><i class="ri-building-line"></i></a>
                          </div>
                          <div class="cell-content">
                            <div class="name">{{element?.name || '--'}}</div>
                            <div class="details">
                              <div class="detail-item"><i class="ri-global-fill"></i><span>{{element?.country ||
                                  '--'}}</span></div>
                              <div class="detail-item"><i class="ri-building-3-fill"></i><span>{{element?.industry ||
                                  '--'}}</span></div>
                            </div>
                            <div class="detail-item"><i class="ri-team-fill"></i><span>{{element?.employee_count ||
                                '--'}} employees</span></div>
                            <div class="detail-item"><i class="ri-calendar-fill"></i><span>{{element?.incorporation_date
                                || '--'}} Estd.</span></div>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <!-------------------Buyer Contact Column--------------------------->

                    <ng-container matColumnDef="contact">
                      <th mat-header-cell *matHeaderCellDef> Contact Info </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="contact-container"
                          *ngIf="element?.panjiva_contact_name || element?.panjiva_phone || element?.other_phone || element?.email || element?.address || element?.website; else noInfo">
                          <div *ngIf="element?.panjiva_contact_name" class="contact-item">
                            <i class="ri-user-fill"></i>
                            <span>{{element?.panjiva_contact_name}}</span>
                          </div>
                          <div *ngIf="element?.panjiva_phone && element?.email" class="phone-email">
                            <div *ngIf="getPhoneNumber(element)" class="contact-item">
                              <i class="ri-phone-fill"></i>
                              <a href="tel:{{getPhoneNumber(element)}}">{{getPhoneNumber(element)}}</a>
                            </div>
                            <div *ngIf="element?.email" class="contact-item">
                              <i class="ri-mail-fill"></i>
                              <a href="mailto:{{element?.email}}">{{element?.email}}</a>
                            </div>
                          </div>
                          <div *ngIf="element?.address" class="contact-item">
                            <i class="ri-map-pin-fill"></i>
                            <span>{{element?.address}}</span>
                          </div>
                          <div *ngIf="element?.website" class="contact-item">
                            <i class="ri-global-fill"></i>
                            <a href="{{element?.website}}" target="_blank">{{element?.website}}</a>
                          </div>
                          <!-- Add similar blocks for other fields -->
                        </div>
                        <ng-template #noInfo><span>No info</span></ng-template>
                      </td>
                    </ng-container>

                    <!-------------------Buyer Finance Column--------------------------->

                    <ng-container matColumnDef="finance">
                      <th mat-header-cell *matHeaderCellDef> Finance </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="financial-metrics-container" *ngIf="element?.shipment_count !== null || element?.annual_revenue !== null || element?.market_capital_value !== null; 
                             else noInfo">
                          <div class="financial-metric-item" *ngIf="element?.shipment_count !== null">
                            <i class="ri-truck-fill"></i>
                            <span>{{element?.shipment_count | number}}</span>
                          </div>
                          <div class="financial-metric-item" *ngIf="element?.annual_revenue !== null">
                            <i class="ri-money-dollar-circle-fill"></i>
                            <span>Annual Turnover: ${{element?.annual_revenue | dollarStringFormat}}</span>
                          </div>
                          <div class="financial-metric-item" *ngIf="element?.market_capital_value !== null">
                            <i class="ri-bar-chart-fill"></i>
                            <span>Market Capitalization: ${{element?.market_capital_value | dollarStringFormat}}</span>
                          </div>
                        </div>
                        <ng-template #noInfo><span>No info</span></ng-template>
                      </td>
                    </ng-container>

                    <!-------------------Buyer Product Column--------------------------->

                    <ng-container matColumnDef="product">
                      <th mat-header-cell *matHeaderCellDef> Product </th>
                      <td mat-cell *matCellDef="let element"> {{getProductDescription(element)}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label={{tabLabelName[4]}}>
          <div class="input-main-div mt-40 custom-tab-body">
            <div class="basic-detail">
              <div class="input-wrap-div">
                <div class="box-width">
                  <div class="formfield">
                    <label>IS Assignee</label>
                    <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Assignee Name" [(ngModel)]="leadDetails.isAssignee"
                        [disabled]="isDisable">
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width select-box">
                  <div class="formfield">
                    <label>Sub Status</label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Select" [(ngModel)]="leadDetails.status" [disabled]="isDisable">
                        <mat-option *ngFor="let status of leadStatus"
                          [value]="status.value">{{status.name | titlecase}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="box-width select-box" *ngIf="leadDetails.status == 'DROPPED'">
                  <div class="formfield">
                    <label>Drop Stage</label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Select" [(ngModel)]="leadDetails.dropStage" [disabled]="isDisable">
                        <mat-option *ngFor="let stage of getDropStage()" [value]="stage">{{stage.replaceAll("_"," ") | titlecase}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="input-wrap-div mt-20">
                <div class="box-width select-box">
                  <div class="formfield">
                    <label>Current Stage <span *ngIf="(leadDetails.status == 'IN_PROGRESS')" class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="mog3-matselect">
                      <mat-select placeholder="Select" [(ngModel)]="leadDetails.subStatus" [disabled]="isDisable" #subStatusCtrl="ngModel" [required]="(leadDetails.status == 'IN_PROGRESS')" id="subStatus">
                        <mat-option *ngFor="let status of getSubStatus()" [value]="status">{{status}}</mat-option>
                      </mat-select>
                      <mat-error class="margin-top-10" *ngIf="(leadDetails.status == 'IN_PROGRESS' && subStatusCtrl.invalid) || isSubmitted">
                        Current Stage is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- <div class="box-width-big">
                <div class="formfield">
                  <label>ISA Comments</label>
                  <mat-form-field class="mog3_matinput" appearance="outline">
                      <input matInput placeholder="Enter Comments" [(ngModel)]="leadDetails.isaRemarks" [disabled]="isDisable">
                  </mat-form-field>
              </div>
              </div> -->
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="tracking-container">
    <div class="tabing-box">
      <mat-tab-group [(selectedIndex)]="selectedIndexTracking" (selectedTabChange)="tabChangedTracking($event)">
        <!---------------------Comment Tab ----------------------------------->
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="label-badge-container">
              <span class="tab-label"> {{trackingLabel[0]}} </span>
              <span matBadge="{{totalComments.length}}" matBadgeOverlap="false" class="custom-badge"></span>
            </div>
          </ng-template>
          <div #commentContent class="comment-section">
            <ng-container *ngIf="totalComments.length > 0; else noCommentsTemplate">
              <div class="no-comment">
                <i class="ri-discuss-fill"></i> Use <b>this space</b> to annotate and track
                the
                progress of this Lead.
              </div>
              <div class="comment" *ngFor="let comment of totalComments;let i = index">
                <div class="comment-base">
                  <div class="avatar-area"
                    [ngClass]="{'blue-area':!comment.byUser.writeAccess,'green-area': comment.byUser.writeAccess}">
                    <div class="user-circle"
                      [ngClass]="{'blue-circle':!comment.byUser.writeAccess,'green-circle': comment.byUser.writeAccess}">
                      {{comment?.byUser?.initial }}</div>
                    <div class="comment-header">
                      <div class="comment-author-time">
                        <div class="comment-author">{{ comment?.byUser?.name }}</div>
                        <div class="comment-time">{{ setDateTime(comment)}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="comment-area">
                    <div class="comment-body">
                      <span style="white-space: pre" [innerHTML]="comment.message" *ngIf="!comment.isEditing"></span>
                      <textarea *ngIf="comment.isEditing" [(ngModel)]="comment.message"
                        (keydown.enter)="$event.preventDefault(); updateComment(comment)"></textarea>
                    </div>
                  </div>
                </div>
                <div *ngIf="comment.byUser.writeAccess" class="comment-actions">
                  <button *ngIf="!comment.isEditing" class="action-button" (click)="editComment(comment)">Edit</button>
                  <button *ngIf="comment.isEditing" class="action-button green-save"
                    (click)="updateComment(comment)">Save</button>
                  <button class="action-button" (click)="deleteComment(i)">Delete</button>
                </div>
              </div>
            </ng-container>
            <ng-template #noCommentsTemplate>
              <div class="no-comment">
                <i class="ri-discuss-fill"></i> No comments have been added. Use <b>this space</b> to annotate and track
                the
                progress of this Lead.
              </div>
            </ng-template>
          </div>
          <form [formGroup]="commentForm">
            <div class="comment-form">
              <mat-form-field appearance="outline">
                <textarea matInput formControlName="comment"
                  placeholder="Type your comment or update for this lead..."></textarea>
              </mat-form-field>
              <span *ngIf="commentForm.controls.comment.invalid && commentForm.controls.comment.touched"
                class="has-error">
                Comment field cannot be empty.
              </span>
              <button (click)="addComment()">Post Comment</button>
            </div>
          </form>
        </mat-tab>
        <mat-tab label={{trackingLabel[1]}}>
          <!----------------------------------------- History Tab ---------------------------------------------------->
          <div class="history-tab">
          <div *ngFor="let history of preQualifiedHistory; let i = index" class="issue-update">

            <!-- Displaying the initial of the action user -->
            <div class="user-circle blue-circle">
              {{history?.actionUser?.initial || '--'}}
            </div>

            <!-- Switching based on the history type -->
            <div class="issue-content" [ngSwitch]="history.historyType">

              <!-- When the history type is LEAD -->
              <ng-container *ngSwitchCase="'LEAD'">
                <div class="user-action">
                  <!-- Showing the name of the user who created the lead -->
                  {{history?.actionUser?.name || '--'}} created this Lead
                  <!-- Showing the date when the lead was created -->
                  <span class="user-action__date">{{history?.actionDate | timeAgo}}</span>
                </div>
              </ng-container>

              <!-- When the history type is LEAD_READY -->
              <ng-container *ngSwitchCase="'LEAD_READY'">
                <div class="user-action">
                  <!-- Showing the name of the user who updated the lead -->
                  {{history?.actionUser?.name || '--'}} updated this Lead
                  <!-- Showing the date when the lead was updated -->
                  <span class="user-action__date">{{history?.actionDate | timeAgo}}</span>
                </div>
                <div class="issue-description" *ngIf="history?.historyType !== 'LEAD'">
                  <span class="status-change"><b>{{history?.oldValue?.Assignee || 'None'}}</b></span>
                  &nbsp;→&nbsp;
                  <span>{{history?.updatedValue?.Assignee || 'None'}}</span>
                </div>
              </ng-container>

              <!-- When the history type is LEAD_ASSIGNEE -->
              <ng-container *ngSwitchCase="'LEAD_ASSIGNEE'">
                <div class="user-action">
                  <!-- Showing the name of the user who added the assignee -->
                  {{history?.actionUser?.name || '--'}} {{history?.operation === "Updated" ? "changed" : "added"}} assignee
                  <!-- Showing the date when the assignee was added -->
                  <span class="user-action__date">{{history?.actionDate | timeAgo}}</span>
                </div>
                <div class="issue-description" *ngIf="history?.historyType !== 'LEAD'">
                  <span class="status-change"><b>{{history?.oldValue?.leadStatus?.name || 'None'}}</b></span>
                  &nbsp;→&nbsp;
                  <span>{{history?.updatedValue?.leadStatus?.name || 'None'}}</span>
                </div>
              </ng-container>

              <!-- When the history type is LEAD_WATCHER -->
              <ng-container *ngSwitchCase="'LEAD_WATCHER'">
                <div class="user-action">
                  <!-- Showing the name of the user who added the watcher -->
                  {{history?.actionUser?.name || '--'}} added watcher
                  <!-- Showing the date when the watcher was added -->
                  <span class="user-action__date">{{history?.actionDate | timeAgo}}</span>
                </div>
                <div class="issue-description" *ngIf="history?.historyType !== 'LEAD'">
                  <span class="status-change"><b>{{history?.updatedValue?.leadWatcher?.name || 'None'}}</b></span>
                </div>
              </ng-container>

              <!-- Default case if none of the above matches -->
              <ng-container *ngSwitchDefault>
                <div class="user-action">
                  <!-- Displaying unhandled operation type if none of the cases match -->
                  Unhandled operation type
                </div>
              </ng-container>

            </div> <!-- End of issue-content -->
          </div> <!-- End of issue-update -->
        </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>