import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { MatDialog, MatTabChangeEvent } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';
import { SidService } from 'src/app/component/sid/services/sid.service';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PreQualifiedLaneMoveComponent } from '../pre-qualified-lane-move/pre-qualified-lane-move.component';
import * as moment from 'moment';
import { PagesService } from 'src/app/pages/pages.service';
@Component({
  selector: 'app-qualified-tabing',
  templateUrl: './qualified-tabing.component.html',
  styleUrls: ['./qualified-tabing.component.scss'],
  animations: [
    trigger('buttonAnimation', [
      state('getDetails', style({
        color: '#3067E9'
      })),
      state('viewDetails', style({
        color: '#15a915'
      })),
      transition("getDetails <=> viewDetails", [
        animate('0.5s ease-in-out')
      ])
    ])
  ]
})
export class QualifiedTabingComponent implements OnInit {
  @ViewChild('buyerContent') private buyerContent: ElementRef;
  @ViewChild('contactContent') private contactContent: ElementRef;
  @ViewChild('commentContent') private commentContent: ElementRef;


  @Input() boardId: any;
  leadDetails: any = {};
  panjivaButtonState: string = 'getDetails';
  suppliers: any = [];
  buyers: any = [];
  buyerCount: number = 0;
  isPresent: Boolean = false;
  isSearched: Boolean = false;
  selectedIndex: number = 0;
  selectedIndexTracking: number = 0;
  keyValueForm: FormGroup;
  contactForm: FormGroup;
  buyerDataList
  contactDetailList
  preQualifiedHistory: any[] = [];
  trackingLabel = ["Comments", "History"]
  displayColumns: string[] = ['name', 'contact', 'finance', 'product'];
  stateList: any = [];
  disableDate: boolean = true;
  tabLabelName = ["Exporter", "Buyer", "Summary", "Panjiva Data", "Inside Sales"];
  domesticLabelName = ["Client", "Buyer/Supplier", "Summary", "Panjiva Data", "Inside Sales"];
  leadStatus: any[] = [{ name: "Fresh Lead", value: "FRESH_LEAD", checked: false }, { name: "In Progress", value: "IN_PROGRESS", checked: false }, { name: "Dropped", value: "DROPPED", checked: false }];
  templateList: any = [];
  selectedTemplate: any = {}
  supplierContacted: any = [{ key: 'Yes', value: true }, { key: 'No', value: false }];
  eximSubStatusList: any = ["Contacted - DNP", "Contacted - Followup", "Interested - Buyer details awaited", "Interested - Meeting Alligned", "Contact in Future", "Not Interested - Customer Reject", "Not Interested - Out of Scope", "Transferred to RSM", "Onboarded"]
  eximDropStageList: any = ["Not Interested", "Not relevant", "No Contact Established", "Deals with Others","SUFFICIENT_BANK_LIMITS","CASH_RICH","ADVANCE_PAYMENTS","BELOW_INVESTMENT_GRADE_BUYER","UNAPPROVED_GEOGRAPHY","LOW_T_O","JUNK","TRADER_MERCHANT","PRICING_REJECT","WORKING_WITH_COMPETITION","Other"];
  domesticSubStatusList: any =  ["Interested- Dropped Mail/ WhatsApp", "Interested- Doc Pending", "Interested- Meeting Aligned", "Interested- Meeting Done", "Transferred to RM", "Onboarded", "Follow-Up"];
  domesticDropStageList: any = ["Not Interested", "Unresponsive", "Not Eligible", "Rejected", "Duplicate", "Low Turnover", "Unrated Buyer ", "Negative Industry", "Not Required", "Not Relevant", "Language Barrier", "Fake Enquiry","Already Financed/Overleveraged","Other"];
  leadSourceList: any = ["Inside Sales", "Channel Partner", "Inbound Query", "Marketing", "Self Sourced"];
  debounce: any;
  buyerMatchings: any[] = [];
  searchTerms = new Subject<string>();
  totalComments: any = [];
  commentForm: FormGroup;
  isSubmitted: boolean;
  @ViewChild('subStatusCtrl') subStatusControl!: NgModel;

  constructor(private router: Router, public preSaleService: PresaleService, private formBuilder: FormBuilder, public sidService: SidService, public tostrService: ToastrService, public dialog: MatDialog, public _kService: KanbanService, public preSalesService: PresaleService, public pagesServices: PagesService) {
    const items = [];
    this.commentForm = new FormGroup({
      comment: new FormControl(null, Validators.required)
    });
    this.keyValueForm = this.formBuilder.group({
      items: this.formBuilder.array(items)
    });
    this.contactForm = this.formBuilder.group({
      details: this.formBuilder.array(items)
    });
  }

  ngOnInit() {
    this.searchTerms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term: string) => this.searchPanjivaBuyer(term))
    ).subscribe(results => {
      this.buyerMatchings = results;
    });

    this.contactForm = this.formBuilder.group({
      details: this.formBuilder.array([this.createContact()])
    })
    this.keyValueForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createBuyer()])
    })
    this.getLeadDetails(this.boardId);
    this.getStates();
    this.getTemplate();
    this.getPreComments();
    this.pagesServices.updateDetails.subscribe(res => {
      this.leadDetails.isAssignee = res.userName;
    })
  }

  getPreComments() {
    this.preSalesService.getPreQualComment(this.boardId).subscribe(res => {
      this.totalComments = res['result'];
      if (this.totalComments.length > 0) {
        setTimeout(() => {
          this.scrollToBottom(this.commentContent);
        }, 0);
      }
    })
  }

  searchBuyer(term: string): void {
    if (term.length > 3) {
      this.searchTerms.next(term);
    }
  }

  getStates() {
    this.sidService.getStates().subscribe(res => {
      this.stateList = res['states_india'];
    })
  }

  getPanjivaTable() {
    let obj = {
      name: this.leadDetails.exporterName
    }
    this._kService.gePanjiva(obj).subscribe(res => {

    })
  }

  navigateToPrequalifiedDetails() {
    this.router.navigate(['/qualified-tabing']); // Navigate to the PrequalifiedDetailsComponent
  }

  navigateBackToLeads() {
    this.router.navigate(['/credlixboard']);
  }

  // navigateToNewLeads(id){
  //   this.router.navigate([`/cardDetails/`,id])
  // }
  getLeadDetails(id) {
    this.preSaleService.leadDetailSearchById(id).subscribe(res => {
      this.leadDetails = res['result'];
      if (this.leadDetails.contactDetailList && this.leadDetails.contactDetailList.length > 0) {
        this.setContactValue(this.leadDetails.contactDetailList);
      }
      if (this.leadDetails.buyerDataList) {
        this.setBuyerValue(this.leadDetails.buyerDataList);
      }
      if(this.leadDetails.businessType == 'Domestic'){
        this.tabLabelName[0] = 'Burrower';
      }
    })
  }

  get isDisable() {
    if (this.leadDetails.status == 'MOVE_TO_LOS') {
      return true;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;
    if (this.selectedIndex === 3) {
      this.fetchPanjivaDetails();
    }
    // this.updateLeadForm();
    // this.preSaleService.updateLeads(this.leadDetails).subscribe(res => {
    //   if (res['status'] == 200) {
    //     this.tostrService.success(`Data Updated Succesfully`)
    //   }
    // })
  }

  tabChangedTracking(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndexTracking = tabChangeEvent.index;
    if (this.selectedIndexTracking === 1) {
      if (this.preQualifiedHistory.length == 0) {
        this.getPrequalifiedHistoryTable(this.boardId);
      }
    }
    // this.updateLeadForm();
    // this.preSaleService.updateLeads(this.leadDetails).subscribe(res => {
    //   if (res['status'] == 200) {
    //     this.tostrService.success(`Data Updated Succesfully`)
    //   }
    // })
  }

  getPrequalifiedHistoryTable(id) {
    this.preSalesService.getPreHistory(id).subscribe(res => {
      if (res['status'] == 200) {
        this.preQualifiedHistory = res['result'];
      }
    })
  }

  submitDetails() {
    const dialogRef = this.dialog.open(PreQualifiedLaneMoveComponent, {
      disableClose: true,
      width: '50%',
      height: '50%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.id) {
        this.updateLead(result.id);
      }
    })
  }

  moveLeadToLOS(id) {
    this.preSaleService.leadMoveToLOSState(this.leadDetails.id, id).subscribe(res => {
      if (res['status'] == 200) {
        this.tostrService.success("Lead Move To LOS Successfully");
        this.navigateBackToLeads();
      }
    })
  }

  updateLead(id) {
    this.dropStatus();
    this.preSaleService.updateLeads(this.leadDetails).subscribe(res => {
      if (res['status'] == 200) {
        this.moveLeadToLOS(id);
      }
    });
  }

  nextTab() {
    this.selectedIndex += 1;
  }

  dropStatus() {
    if (this.leadDetails.status != 'DROPPED') {
      this.leadDetails.dropStage = null;
    }
  }

  saveButton() {
    this.updateLeadForm();
    this.dropStatus();
    if (this.leadDetails.businessType == 'Domestic' && !this.validateSubStatus()){
      return ;
    }
    this.preSaleService.updateLeads(this.leadDetails).subscribe(res => {
      if (res['status'] == 200) {
        this.getLeadDetails(this.boardId);
        this.tostrService.success(`${this.tabLabelName[this.selectedIndex]} Updated Succesfully`);
      }
    })
  }

  validateSubStatus() {
    if (this.leadDetails.businessType == 'Domestic' && this.leadDetails.status == 'IN_PROGRESS' && !this.leadDetails.subStatus) {
      this.isSubmitted = true;
      this.subStatusControl.control.markAsTouched();
      this.subStatusControl.control.markAsDirty();
      this.selectedIndex = 2;
      // this.tostrService.error("Please Select Current Stage for In Progress Sub Status");
      return false;
    }else{return true;}
  }

  updateLeadForm() {
    this.leadDetails['contactDetailList'] = this.contactForm.value.details.filter(item => item.name && item.name.trim() !== '');;
    this.leadDetails['buyerDataList'] = this.keyValueForm.value.items.filter(item => item.buyerName && item.buyerName.trim() !== '');
  }

  addBuyer() {
    this.buyerDataList = this.keyValueForm.get('items') as FormArray;
    this.buyerCount = this.buyerDataList.length;
    this.buyerDataList.push(this.createBuyer());
    setTimeout(() => {
      this.scrollToBottom(this.buyerContent);
    }, 0);
  }

  createBuyer(): FormGroup {
    return this.formBuilder.group({
      buyerName: ['', Validators.required],
      buyerRegion: [],
      buyerCategory: [],
      approvedBy: [],
      id: []
    });
  }

  deleteBuyerRow(element: any, index: number) {
    const removeAndNotify = () => {
      this.buyerDataList.removeAt(index);
      this.buyerCount = this.buyerDataList.length;
      this.tostrService.success("Successfully Deleted");
    };

    if (element.value.id) {
      const obj = {
        leadId: this.leadDetails.id,
        buyerId: this.leadDetails.buyerDataList[index].id
      };
      this.preSaleService.deleteBuyer(obj).subscribe(
        res => {
          removeAndNotify();
        },
      );
    } else {
      removeAndNotify();
    }
  }

  setBuyerValue(item) {
    this.buyerCount = item.length;
    const formArray = new FormArray([]);
    for (let x of item) {
      formArray.push(this.formBuilder.group({
        buyerName: x.buyerName,
        buyerRegion: x.buyerRegion,
        buyerCategory: x.buyerCategory,
        approvedBy: x.approvedBy,
        id: x.id
      }));
    }
    this.keyValueForm.setControl('items', formArray);
    this.buyerDataList = this.keyValueForm.get('items') as FormArray;
  }

  doSubmit() {
  }

  addContact() {
    this.contactDetailList = this.contactForm.get('details') as FormArray;
    this.contactDetailList.push(this.createContact());
    setTimeout(() => {
      this.scrollToBottom(this.contactContent);
    }, 0);
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      name: [],
      designation: [],
      phone: [],
      email: [],
      alternatePhone: [],
      primary: [],
      id: []
    });
  }

  setContactValue(item) {
    const formArray = new FormArray([]);
    for (let x of item) {
      formArray.push(this.formBuilder.group({
        name: x.name,
        designation: x.designation,
        phone: x.phone,
        email: x.email,
        alternatePhone: x.alternatePhone,
        primary: x.primary,
        id: x.id
      }));
    }
    this.contactForm.setControl('details', formArray);
    this.contactDetailList = this.contactForm.get('details') as FormArray;
  }

  deleteContactRow(element, index) {
    if (element.value.id) {
      let obj = {
        leadId: this.leadDetails.id,
        contactId: this.leadDetails.contactDetailList[index].id
      }
      this.preSaleService.deleteContact(obj).subscribe(res => {
        this.tostrService.success("Successfully Deleted");
        this.contactDetailList.removeAt(index);
      })
    } else {
      this.tostrService.success("Successfully Deleted");
      this.contactDetailList.removeAt(index);
    }
  }

  makePrimary(event, index) {
    this.checkedContactAlreadyPrimary();
    let contactId = this.leadDetails.contactDetailList[index].id;
    let obj = {
      id: contactId,
      leadId: this.leadDetails.id
    }
    this.preSaleService.markAsPrimary(obj).subscribe(res => {
      this.tostrService.success("Contact Marked Primary Successfully")
    })
  }

  checkedContactAlreadyPrimary() {
    if (this.leadDetails.contactDetailList.length < 0) {
      this.leadDetails.contactDetailList.forEach(val => {
        if (val.primary == true) {
          this.tostrService.error("Primary Contact is already exist");
          return false;
        }
      })
    }
  }

  getTemplate() {
    this.preSaleService.getWhatsAppTemplate().subscribe(res => {
      this.templateList = res['result'];
    })
  }

  redirectUser(val) {
    this.checkDefautTemplate();
    let obj = {
      leadId: this.leadDetails.id,
      isWhatsAppSelected: val,
      messageTemplateId: this.selectedTemplate.id
    }
    this.preSaleService.sendWhatsApp(obj).subscribe(res => {
      window.open(res['result'])
    })
  }

  checkDefautTemplate() {
    this.templateList.forEach(val => {
      if (val.default == true) {
        this.selectedTemplate = val
      }
    })
  }

  handlePanjivaState() {
    if (this.panjivaButtonState === 'getDetails') {
      this.requestPanjivaDetails();
      this.panjivaButtonState = 'viewDetails';
    } else if (this.panjivaButtonState === 'viewDetails') {
      this.selectedIndex = 3;
    }

  }


  requestPanjivaDetails() {
    let obj = {
      name: this.leadDetails.exporterName
    }
    this._kService.requestPanjivaDetails(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.tostrService.success(res['message'])
        this.panjivaButtonState = 'viewDetails';
      }
    })
  }

  searchPanjivaBuyer(value: string): Observable<any[]> {
    let obj = {
      name: value,
      type: 'buyer'
    };
    return new Observable(observer => {
      this._kService.searchPanjivaDetails(obj).subscribe(
        res => {
          if (res['status'] === 200) {
            observer.next(res['result'].companies);
            observer.complete();
          } else {
            observer.next(res['result'].companies);
            observer.complete();
          }
        }
      );
    });
  }

  fetchPanjivaDetails() {
    let obj = {
      name: this.leadDetails.exporterName
    }
    this._kService.fetchPanjivaDetails(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.suppliers = [res['result'].supplier]
        this.buyers = res['result'].buyers
        this.isPresent = res['result'].isPresent
        this.isSearched = res['result'].isSearched
      }
    })
  }

  getProductDescription(element: any): string {
    return element && element.products && element.products[0] ? element.products[0].description : 'No info';
  }

  getPhoneNumber(element: any): string {
    let phoneNumber;
    if (element.panjiva_phone) {
      phoneNumber = element.panjiva_phone;
    } else if (element.other_phone) {
      const otherPhones = element.other_phone.split(', ');
      if (otherPhones.length > 0) {
        phoneNumber = otherPhones[0];
      }
    }
    return phoneNumber
  }

  isContactFormValid(): boolean {
    const contactList = this.contactForm.get('details') as FormArray;
    if (contactList) {
      const lastIndex = contactList.length - 1;
      if (lastIndex >= 0) {
        const lastForm = contactList.at(lastIndex) as FormGroup;
        const contactControlName = lastForm.get('name');
        return contactControlName && contactControlName.valid && contactControlName.value && contactControlName.value.trim() !== '';
      }
    }
    return true;
  }

  isBuyerFormValid() {
    const buyerList = this.keyValueForm.get('items') as FormArray;
    if (buyerList) {
      const lastIndex = buyerList.length - 1;
      if (lastIndex >= 0) {
        const lastForm = buyerList.at(lastIndex) as FormGroup;
        const buyerFormControlName = lastForm.get('buyerName');
        return buyerFormControlName && buyerFormControlName.valid && buyerFormControlName.value && buyerFormControlName.value.trim() !== '';
      }
    }
    return true;
  }

  scrollToBottom(element: ElementRef): void {
    element.nativeElement.scrollTop = element.nativeElement.scrollHeight;
  }

  addComment() {
    if (this.commentForm.valid) {
      let obj = {
        "leadId": this.boardId,
        "message": this.commentForm.value.comment
      }
      this.preSalesService.addPreQualComment(obj).subscribe(res => {
        if (res['status'] == 200) {
          this.totalComments.push(res['result']);
          this.commentForm.reset();
        }
      })
    } else {
      // Form is not valid
      console.log('Textarea cannot be empty');
    }
  }

  editComment(comment: Comment) {
    comment['isEditing'] = true;  // Turn on edit mode for clicked comment
  }

  deleteComment(index: number) {
    let id = this.totalComments[index].id;
    this.preSalesService.deletePreQualComment(id).subscribe(res => {
      if (res['status'] == 200) {
        this.totalComments.splice(index, 1);
      }
    }, err => {
      this.tostrService.error("Unable to delete, server error");
    });

  }

  updateComment(comment: Comment) {
    this.preSaleService.updatePreQualComment(comment).subscribe(res => {
      if (res['status'] == 200) {
        comment['isEditing'] = false;
      }
    }, err => {
      this.tostrService.error("Unable to update, server error");
      comment['isEditing'] = false;
    });
  }

  setDateTime(comment) {
    return moment(comment.updatedAt).format("dddd, MMMM Do YYYY, h:mm:ss a");
  }

  getDropStage() {
    if (['Exim','Export'].includes(this.leadDetails.businessType)) {
      return this.eximDropStageList;
    } else if (this.leadDetails && this.leadDetails.businessType && this.leadDetails.businessType === 'Domestic') {
      return this.domesticDropStageList;
    }
    return [];
  }

  getLabelNameList(){
    if(this.leadDetails && this.leadDetails.businessType && this.leadDetails.businessType === 'Domestic') {
      return this.domesticLabelName;
    }else{
      return this.tabLabelName
    }
  }

  getSubStatus() {
    if (this.leadDetails && this.leadDetails.businessType && this.leadDetails.businessType === 'Exim') {
      return this.eximSubStatusList;
    } else if (this.leadDetails && this.leadDetails.businessType && this.leadDetails.businessType === 'Domestic') {
      if (this.leadDetails.status == 'IN_PROGRESS') {
        return [
          "Interested – Initial Contact", "Interested – Doc Pending", "Interested – Meeting Scheduled", "Interested – Meeting Completed", "Transferred to RM", "Active Follow-Up","Other"]
      }
      return this.domesticSubStatusList;
    }
    return [];
  }
}

